<template>
  <div>
    <PageHeader />
     <b-overlay :show="isLoading">
       <template v-if="trackingLinkDetail">
         <b-row>
           <b-col>
             <h3 class="mb-0">
               {{ trackingLinkDetail.name }}
             </h3>
             <a :href="trackingLinkUrl" target="_blank">{{ trackingLinkUrl }}</a> <br />
             <a :href="trackingLinkDetail.targetUrl" class="text-muted">({{ trackingLinkDetail.targetUrl }})</a>
           </b-col>
           <b-col class="text-right">
             <b-button
                 to="/tracking-link/list"
             >
               {{ $t('buttons.back') }}
             </b-button>
           </b-col>
         </b-row>
         <div class="mb-3">
         </div>
         <b-row>
           <b-col md="5" lg="4">
             <b-card sub-title="คลิกทั้งหมด">
               <hr />
               <h2>{{ trackingLinkDetail.totalClick }} <small class="font-size-18">ครั้ง</small></h2>
             </b-card>
             <b-card sub-title="คลิกไม่ซ้ำ">
               <hr />
               <h2>{{ trackingLinkDetail.totalUniqueClick }} <small class="font-size-18">ครั้ง</small></h2>
             </b-card>
           </b-col>
           <b-col md="7" lg="8">
             <b-card sub-title="ยูสเซอร์">
               <hr />
               <b-row>
                 <b-col cols="6" class="mb-2">
                   <span class="text-muted">สมัครสมาชิก</span>
                   <h2 class="my-0">
                     {{ trackingLinkDetail.totalRegister }}
                     <small class="font-size-18">ยูส</small>
                   </h2>
                   <b-collapse id="collapse-user-agent-detail" class="py-2">
                     <b-list-group>
                       <b-list-group-item
                           v-for="(detailAgent, index) in trackingLinkDetail.detailAgents"
                           :key="index"
                           class="d-sm-flex justify-content-sm-between align-items-sm-center"
                       >
                         {{ detailAgent.agentName }}
                         <br />
                         <span>{{ detailAgent.totalRegister | money }} ยูส</span>
                       </b-list-group-item>
                     </b-list-group>
                   </b-collapse>
                 </b-col>
                 <b-col cols="6" class="text-right">
                   <b-button
                       v-b-toggle="`collapse-user-agent-detail`"
                       size="sm"
                       variant="outline-info"
                       @click.prevent
                   >
                     <i class="uil uil-plus-circle"></i> รายละเอียด
                   </b-button>
                 </b-col>
                 <b-col cols="6" class="mb-2">
                   <span class="text-muted">ฝาก</span>
                   <h2 class="my-0">
                     {{ trackingLinkDetail.totalDepositCount | money }}
                     <small class="font-size-18">ยูส</small>
                   </h2>
                   <b-collapse id="collapse-user-agent-detail" class="py-2">
                     <b-list-group>
                       <b-list-group-item
                           v-for="(detailAgent, index) in trackingLinkDetail.detailAgents"
                           :key="index"
                           class="d-sm-flex justify-content-sm-between align-items-sm-center"
                       >
                         {{ detailAgent.agentName }}
                         <br />
                         <span>{{ detailAgent.totalDepositCount | money }} ยูส</span>
                       </b-list-group-item>
                     </b-list-group>
                   </b-collapse>
                 </b-col>
                 <b-col cols="6" class="mb-2">
                   <span class="text-muted">จำนวน</span>
                   <h2 class="my-0">
                     {{ trackingLinkDetail.totalDepositAmount | currency }}
                     <small class="font-size-18">บาท</small>
                   </h2>
                   <b-collapse id="collapse-user-agent-detail" class="py-2">
                     <b-list-group>
                       <b-list-group-item
                           v-for="(detailAgent, index) in trackingLinkDetail.detailAgents"
                           :key="index"
                           class="d-sm-flex justify-content-sm-between align-items-sm-center"
                       >
                         {{ detailAgent.agentName }}
                         <br />
                         <span>{{ detailAgent.totalDepositAmount | currency }} บาท</span>
                       </b-list-group-item>
                     </b-list-group>
                   </b-collapse>
                 </b-col>
                 <b-col cols="6">
                   <span class="text-muted">ถอน</span>
                   <h2 class="my-0">
                     {{ trackingLinkDetail.totalWithdrawCount | money }}
                     <small class="font-size-18">ยูส</small>
                   </h2>
                   <b-collapse id="collapse-user-agent-detail" class="py-2">
                     <b-list-group>
                       <b-list-group-item
                           v-for="(detailAgent, index) in trackingLinkDetail.detailAgents"
                           :key="index"
                           class="d-sm-flex justify-content-sm-between align-items-sm-center"
                       >
                         {{ detailAgent.agentName }}
                         <br />
                         <span>{{ detailAgent.totalWithdrawCount | money }} ยูส</span>
                       </b-list-group-item>
                     </b-list-group>
                   </b-collapse>
                 </b-col>
                 <b-col cols="6">
                   <span class="text-muted">จำนวน</span>
                   <h2 class="mt-0 mb-1">
                     {{ trackingLinkDetail.totalWithdrawAmount | currency }}
                     <small class="font-size-18">บาท</small>
                   </h2>
                   <b-collapse id="collapse-user-agent-detail" class="py-2">
                     <b-list-group>
                       <b-list-group-item
                           v-for="(detailAgent, index) in trackingLinkDetail.detailAgents"
                           :key="index"
                           class="d-sm-flex justify-content-sm-between align-items-sm-center"
                       >
                         {{ detailAgent.agentName }}
                         <br />
                         <span>{{ detailAgent.totalWithdrawAmount | currency }} บาท</span>
                       </b-list-group-item>
                     </b-list-group>
                   </b-collapse>
                 </b-col>
               </b-row>
             </b-card>
           </b-col>

           <b-col cols="12">
             <b-card
                 v-for="trackingPromotion in trackingLinkDetail.promotions"
                 :key="trackingPromotion.id"
                 body-class="pt-0"
                 header-class="pb-0"
             >
               <template #header>
                 <b class="font-size-22 text-success">{{ trackingPromotion.promotionName }}</b>
                 <b-button
                     v-b-toggle="`collapse-promotion-agent-detail-${trackingPromotion.id}`"
                     size="sm"
                     variant="outline-info"
                     class="ml-3"
                     @click.prevent
                 >
                   <i class="uil uil-plus-circle"></i> รายละเอียด
                 </b-button>
               </template>
               <hr class="mt-3">
              <b-row>
                <b-col cols="6" md="3">
                  <span class="text-muted">รับโปรโมชั่น</span>
                  <h2 class="mt-0">
                    {{ trackingPromotion.totalUserCount | money }} <small class="font-size-18">ยูส</small> /
                    {{ trackingPromotion.totalClaimCount | money }} <small class="font-size-18">ครั้ง</small>
                  </h2>
                  <b-collapse :id="`collapse-promotion-agent-detail-${trackingPromotion.id}`" class="mb-3 d-md-none">
                    <b-list-group>
                      <b-list-group-item
                          v-for="(detailAgent, index) in trackingPromotion.detailAgents"
                          :key="index"
                          class="d-sm-flex justify-content-sm-between align-items-sm-center"
                      >
                        {{ detailAgent.agentName }}
                        <br />
                        <span>
                      {{ detailAgent.totalUserCount | money }} ยูส /
                      {{ detailAgent.totalClaimCount | money }} ครั้ง
                    </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-col>
                <b-col cols="6" md="3">
                  <span class="text-muted">โบนัส</span>
                  <h2 class="mt-0">{{ trackingPromotion.totalBonusAmount | currency }} <small class="font-size-18">เครดิต</small></h2>
                  <b-collapse :id="`collapse-promotion-agent-detail-${trackingPromotion.id}`" class="mb-3 d-md-none">
                    <b-list-group>
                      <b-list-group-item
                          v-for="(detailAgent, index) in trackingPromotion.detailAgents"
                          :key="index"
                          class="d-sm-flex justify-content-sm-between align-items-sm-center"
                      >
                        {{ detailAgent.agentName }}
                        <br />
                        <span>
                          {{ detailAgent.totalBonusAmount | currency }} เครดิต
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-col>
                <b-col cols="6" md="3">
                  <span class="text-muted">ฝาก</span>
                  <h2 class="mt-0">{{ trackingPromotion.totalDepositAmount | currency }} <small class="font-size-18">บาท</small></h2>
                  <b-collapse :id="`collapse-promotion-agent-detail-${trackingPromotion.id}`" class="mb-3 d-md-none">
                    <b-list-group>
                      <b-list-group-item
                          v-for="(detailAgent, index) in trackingPromotion.detailAgents"
                          :key="index"
                          class="d-sm-flex justify-content-sm-between align-items-sm-center"
                      >
                        {{ detailAgent.agentName }}
                        <br />
                        <span>
                          {{ detailAgent.totalDepositAmount | currency }} บาท
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-col>
                <b-col cols="6" md="3">
                  <span class="text-muted">ถอน</span>
                  <h2 class="mt-0">
                    {{ trackingPromotion.totalWithdrawCount | money }} <small class="font-size-18">ครั้ง</small> /
                    {{ trackingPromotion.totalWithdrawAmount | currency }} <small class="font-size-18">บาท</small>
                  </h2>
                  <b-collapse :id="`collapse-promotion-agent-detail-${trackingPromotion.id}`" class="mb-3 d-md-none">
                    <b-list-group>
                      <b-list-group-item
                          v-for="(detailAgent, index) in trackingPromotion.detailAgents"
                          :key="index"
                          class="d-sm-flex justify-content-sm-between align-items-sm-center"
                      >
                        {{ detailAgent.agentName }}
                        <br />
                        <span>
                      {{ detailAgent.totalWithdrawCount | money }} ครั้ง /
                      {{ detailAgent.totalWithdrawAmount | currency }} บาท
                    </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-col>
                <b-col cols="12" class="d-none d-md-block">
                  <b-collapse :id="`collapse-promotion-agent-detail-${trackingPromotion.id}`">
                    <hr />
                    <div
                        v-for="(detailAgent, index) in trackingPromotion.detailAgents"
                        :key="index"
                        class="mb-3"
                    >
                      <span class="text-muted">{{ detailAgent.agentName }}</span>
                      <b-row>
                        <b-col cols="3">
                          <h3 class="mt-0 font-weight-medium">
                            {{ detailAgent.totalUserCount | money }} <small class="font-size-16">ยูส</small> /
                            {{ detailAgent.totalClaimCount | money }} <small class="font-size-16">ครั้ง</small>
                          </h3>
                        </b-col>
                        <b-col cols="3">
                          <h3 class="mt-0 font-weight-medium">{{ detailAgent.totalBonusAmount | currency }} <small class="font-size-16">เครดิต</small></h3>
                        </b-col>
                        <b-col cols="3">
                          <h3 class="mt-0 font-weight-medium">{{ detailAgent.totalDepositAmount | currency }} <small class="font-size-16">เครดิต</small></h3>
                        </b-col>
                        <b-col cols="3">
                          <h3 class="mt-0 font-weight-medium">
                            {{ detailAgent.totalWithdrawCount | money }} <small class="font-size-16">ครั้ง</small> /
                            {{ detailAgent.totalWithdrawAmount | currency }} <small class="font-size-16">บาท</small>
                          </h3>
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>
             </b-card>
           </b-col>
         </b-row>
       </template>
     </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  page: {
    title: 'ลิงค์การตลาด',
  },
  data() {
    return {
      isLoading: false,
      trackingLink: null
    }
  },
  computed: {
    ...mapGetters(['trackingLinkDetail', 'trackingLinkDetail.totalRegister']),
    trackingLinkId() {
      return this.$route.params.id
    },
    trackingLinkUrl() {
      const baseUrl = process.env.VUE_APP_TRACKING_BASE_URL.endsWith('/') ? process.env.VUE_APP_TRACKING_BASE_URL : process.env.VUE_APP_TRACKING_BASE_URL + '/'
      return baseUrl + this.trackingLinkDetail.trackingCode
    }
  },
  async created() {
    this.isLoading = true
    await this.fetchTrackingLinkData(this.trackingLinkId)
    this.isLoading = false
  },
  methods: {
    ...mapActions(['fetchTrackingLinkData']),
  },
}
</script>